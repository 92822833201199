<template>
  <b-navbar 
    ref="nav" 
    type="is-primary" 
    shadow 
    transparent
    wrapper-class="container" >
    <template #brand>
      <b-navbar-item tag="router-link" to="/" >
        <img v-if="logo" :src="logo" :sidebar-menu-applt="nombre_comercial" />
        <span class="is-capitalized" v-else>{{nombre_comercial}}</span>
      </b-navbar-item>
    </template>
    
    <template #end v-if="user">
      <b-navbar-dropdown>
        <template #label>
          <b-icon icon="user" class="mr-1" /> {{full_name}}
        </template>
        <b-navbar-item tag="router-link" to="/auth/profile" >
          <b-icon icon="user" class="mr-1" /> Mi cuenta
        </b-navbar-item>
        <b-navbar-item @click.prevent="handleLogout">
          <b-icon icon="sign-out-alt" class="mr-1" /> Salir
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      user: 'auth/user',
      full_name: "auth/full_name",
      logo: "entitie/logo_url",
      nombre_comercial: "entitie/nombre_comercial"
    }),
  },
  methods: {
    async handleLogout() {
      await this.$store.dispatch("auth/LogoutRequest");
      this.$router.push({name: "AuthSignIn"})
    },
  },
};
</script>
